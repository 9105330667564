import React from 'react';
import { DiagnosticPayload } from 'fablecast-client';
import { Card, CardContent, Typography, IconButton } from '@mui/joy';
import { BranchUserApi, GitgraphUserApi } from '@gitgraph/core';
import { ReactSvgElement } from '@gitgraph/react/lib/types';
import { Gitgraph } from '@gitgraph/react';
import CloseIcon from '@mui/icons-material/Close';
import { v4 } from 'uuid';

type EventMode = 'Finalized' | 'ClientOnly' | 'Prospective'

export class ChainNode {
  readonly parentId: string | undefined
  readonly nodes: Map<EventMode, DiagnosticPayload>
  readonly id: string | undefined
  readonly children: ChainNode[]
  readonly depth: number
  readonly size: number

  constructor(
    parentId: string | undefined = undefined,
    nodes: Map<EventMode, DiagnosticPayload> = new Map(),
    id: string | undefined = undefined,
    children: ChainNode[] = []
  ) {
    this.parentId = parentId
    this.nodes = nodes
    this.id = id
    this.children = children
    var maxChildDepth = 0
    var size = 0
    for (const child of children) {
      if (child.depth > maxChildDepth) {
        maxChildDepth = child.depth
      }
      size += child.size
    }
    this.depth = maxChildDepth + 1
    this.size = size + 1
  }

  attachEvent(diagnosticPayload: DiagnosticPayload): ChainNode {
    var out = this.attachEventRaw(diagnosticPayload)

    // Drop old bits of tree
    while (out.depth > 100) {
      const t = out.tryTruncate(90)
      if (t) {
        out = t
      } else {
        break;
      }
    }
    return out
  }

  tryTruncate(amountOfFinalizedBufferTurns: number): ChainNode | undefined {
    const truncated = this.children.filter(n => n.nodes.has('Finalized'))
    if (truncated.length === 1) {
      if (amountOfFinalizedBufferTurns !== 0 && truncated[0].tryTruncate(amountOfFinalizedBufferTurns - 1) === undefined) {
          return undefined
      }
      return truncated[0]
    } else {
      return undefined
    }
  }

  attachEventRaw(diagnosticPayload: DiagnosticPayload): ChainNode {
    if ((diagnosticPayload.parentId || undefined) === this.id) {

      const event = JSON.parse(diagnosticPayload.eventJson) as {
        id: string,
        type: string,
        narrativeEventType?: string
      }

      const childMatches = this.children.filter(c => c.id === (event.id || undefined))
      var childMatch: ChainNode | undefined = undefined
      if (childMatches.length !== 0) {
        childMatch = childMatches[0]
      }

      if (childMatch) {
        const childNodes: Map<EventMode, DiagnosticPayload> = new Map(childMatch.nodes)
        childNodes.set(diagnosticPayload.mode as EventMode, diagnosticPayload)
        return new ChainNode(
          this.parentId,
          this.nodes,
          this.id,
          [...this.children.filter(c => c.id !== childMatch!.id), new ChainNode(childMatch!.id, childNodes, childMatch!.id, childMatch!.children)]
        )
      } else {
        return new ChainNode(
          this.parentId,
          this.nodes,
          this.id,
          [...this.children, new ChainNode(this.id, new Map([[diagnosticPayload.mode as EventMode, diagnosticPayload]]), event.id, [])]
        )
      }
    } else {
      return new ChainNode(
        this.parentId,
        this.nodes,
        this.id,
        this.children.map(child => child.attachEventRaw(diagnosticPayload))
      )
    }
  }

  
  get(id: string): DiagnosticPayload | undefined {
    if (id === this.id) {
      return this.nodes.get('Finalized') || this.nodes.get('ClientOnly') || this.nodes.get('Prospective') 
    }
    for (const child of this.children) {
      const childOut = child.get(id)
      if (childOut) return childOut
    }
    return undefined
  }
}

export interface DiagnosticsProps {
  diagnostic: DiagnosticPayload | undefined
  narrativeChain: ChainNode
  setCurrentDiagnostic: React.Dispatch<React.SetStateAction<DiagnosticPayload | undefined>>;
}

function Event({ dialogEventJson }: { dialogEventJson: string }) {
  return <pre>{JSON.stringify(JSON.parse(dialogEventJson), null, 2)}</pre>
}

function ChainView({ chain, setCurrentDiagnostic }: {
  chain: ChainNode, 
  setCurrentDiagnostic: React.Dispatch<React.SetStateAction<DiagnosticPayload | undefined>>}) {

  const [component, setComponent] = React.useState<JSX.Element>();
  const [lastRendered, setLastRendered] = React.useState<ChainNode>();
  
  const addToGitGraph = React.useCallback((gitGraph: GitgraphUserApi<ReactSvgElement>, startNode: ChainNode) => {
    gitGraph.clear();

    function commitEvent(branch: BranchUserApi<ReactSvgElement>, payload: DiagnosticPayload, color: string): BranchUserApi<ReactSvgElement> {
      const event = JSON.parse(payload.eventJson) as {
        id: string,
        type: string,
        narrativeEventType?: string
      }
      return branch.checkout().commit({
        subject: event.narrativeEventType || event.type,
        body: event.id,
        style: {
          spacing: 0,
          message: {
            displayAuthor: false,
            displayHash: false,
            color: color
          },
          dot: {
            color
          }
        },
        onClick: (commit) => setCurrentDiagnostic(payload),
        onMessageClick: (commit) => setCurrentDiagnostic(payload)
      })
    }

    function mergeEvent(fromBranch: BranchUserApi<ReactSvgElement>, toBranch: BranchUserApi<ReactSvgElement>, payload: DiagnosticPayload, color: string): BranchUserApi<ReactSvgElement> {      
      return toBranch.merge({
        branch: fromBranch,
        commitOptions: {
          onClick: (commit) => setCurrentDiagnostic(payload),
          onMessageClick: (commit) => setCurrentDiagnostic(payload),
          style: {
            message: {
              display: false
            },
            dot: {
              color
            }
          }
        }
      })
    }

    const nodes: [ChainNode, (BranchUserApi<ReactSvgElement> | undefined), number][] = [[startNode, undefined, 0]]

    while (nodes.length > 0) {
      var [node, prospectiveBranch, distanceBeyondFinal] = nodes.pop()!
      if (node.nodes.size > 0) {

        var commit: BranchUserApi<ReactSvgElement> | undefined = undefined

        if (node.nodes.has('Prospective')) {
          if (prospectiveBranch === undefined) {
            prospectiveBranch = gitGraph.branch({
              name: "Finalized",
              style: {
                lineWidth: 3,
                color: '#27ae60',
                label: {
                  color: '#27ae60',
                  bgColor: '#000000',
                  strokeColor: '#27ae60'
                }
              }
            }).branch({
              name: (JSON.parse(node.nodes.get('Prospective')!.eventJson) as {
                id: string,
                type: string,
                narrativeEventType?: string
              }).id,
              style: {
                lineWidth: 3,
                color: '#c0392b',
                label: {display: false}
              }
            })
          }
          if (commit) {
            commit = mergeEvent(commit, prospectiveBranch, node.nodes.get('Prospective')!, '#c0392b')
          } else {
            commit = commitEvent(prospectiveBranch, node.nodes.get('Prospective')!, '#c0392b')
          }
        }

        if (node.nodes.has('ClientOnly')) {
          const branch = gitGraph.branch({
            name: "Client",
            style: {
              lineWidth: 3,
              color: '#f39c12',
              label: {
                color: '#f39c12',
                bgColor: '#000000',
                strokeColor: '#f39c12'
              }
            }
          })
          if (commit) {
            commit = mergeEvent(commit, branch, node.nodes.get('ClientOnly')!, '#f39c12')
          } else {
            commit = commitEvent(branch, node.nodes.get('ClientOnly')!, '#f39c12')
          }
        }

        if (node.nodes.has('Finalized')) {
          const branch = gitGraph.branch({
            name: "Finalized",
            style: {
              lineWidth: 3,
              color: '#27ae60',
              label: {
                color: '#27ae60',
                bgColor: '#000000',
                strokeColor: '#27ae60',
              }
            }
          })
          if (commit) {
            commit = mergeEvent(commit, branch, node.nodes.get('Finalized')!, '#27ae60')
          } else {
            commit = commitEvent(branch, node.nodes.get('Finalized')!, '#27ae60')
          }
        }
      }
  
      var usedProspective = false;
      for (const child of node.children) {
        var newDistanceBeyondFinal = distanceBeyondFinal
        if (node.nodes.has('Finalized')) {
          newDistanceBeyondFinal = 0
        } else {
          newDistanceBeyondFinal += 1
        }
        
        if (distanceBeyondFinal > 5) {
          continue
        }
        if (node.nodes.has('Prospective') && child.nodes.has('Prospective') && !usedProspective) {
          nodes.push([child, prospectiveBranch, newDistanceBeyondFinal])
          usedProspective = true
        } else {
          nodes.push([child, undefined, newDistanceBeyondFinal])
        }
      }
    }

  }, [setCurrentDiagnostic]);
  
  React.useEffect(() => {
    if (lastRendered === chain) {
      return
    }
    setComponent(<Gitgraph key={v4().toString()}>{(graph) => {
      addToGitGraph(graph, chain)
    }}</Gitgraph>)
    setLastRendered(chain);
  }, [chain, lastRendered, setLastRendered, addToGitGraph, setComponent])

  return <div style={{ height: 'auto' }}>{component || <></>}</div>
}

export default function DiagnosticsView({ diagnostic, narrativeChain, setCurrentDiagnostic }: DiagnosticsProps) {
  var eventColor = '#c0392b';
  var label = "Prospective"
  if (diagnostic?.mode === 'Finalized') {
    eventColor = '#27ae60';
    label = "Finalized"
  } else if (diagnostic?.mode === 'ClientOnly') {
    eventColor = '#f39c12';
    label = "Client"
  }

  let event = undefined
  if (diagnostic) {
    event = JSON.parse(diagnostic!.eventJson) as {
      id: string,
      type: string,
      narrativeEventType?: string
    }
  }
  
  return (<>
      {diagnostic !== undefined && (<Card sx={{ width: '100%', overflowY: 'scroll', height: 'calc(50vh - 64px)' }}>      
      <div>
        <Typography level="title-lg">
          <span style={{ color: eventColor }}>{label}</span>&nbsp;
          Event</Typography>
        <Typography level="title-md">
          Continues from event: {diagnostic.parentId === undefined ? "None" : (<a
            style={{ color: 'rgba(var(--joy-palette-primary-mainChannel) / 1)' }}
            href="/#" onClick={(e) => {
              e.preventDefault()
              setCurrentDiagnostic(narrativeChain.get(diagnostic!.parentId!))
            }}>{diagnostic.parentId}</a>)}
        </Typography>
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
        >
          <CloseIcon onClick={() => setCurrentDiagnostic(undefined)} />
        </IconButton>
      </div>
      {(
        <CardContent orientation="horizontal">
          <Card sx={{ width: '100%' }}>
            <div>
              <Typography level="title-lg">{event!.narrativeEventType || event?.type}</Typography>
              <Typography level="title-sm">ID: {event?.id}</Typography>
            </div>
            <CardContent orientation="horizontal">
              <div>
                <Event dialogEventJson={diagnostic.eventJson} />
              </div>
            </CardContent>
          </Card>
        </CardContent>
      )}
    </Card>)}
    <Card sx={{ width: '100%', overflowY: 'scroll', marginTop: diagnostic === undefined ? undefined : '32px', height: diagnostic === undefined ? 'calc(100vh - 64px)' : 'calc(50vh - 32px)' }}>
      <ChainView chain={narrativeChain} setCurrentDiagnostic={setCurrentDiagnostic} />
    </Card>
  </>);
}