import React from 'react';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import SettingsBox from './components/SettingsBox';
import themeColors from './Colors';
import Sidebar from './components/Sidebar';
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import { signInWithRedirect, fetchAuthSession } from 'aws-amplify/auth';
import { CircularProgress, Typography } from '@mui/joy';
import testEnvVars from './env_vars.test.json';
import prodEnvVars from './env_vars.prod.json';
import PuppetHome from './components/PuppetHome';
import PuppetMain, { puppetLoader } from './components/PuppetMain';
import Fablecast, { FablecastLogin } from './components/Fablecast';
import Narratives, { NarrativeEndpoints } from './components/Narratives';
import LangFuse from './components/LangFuse';
import { FablecastProvider } from '@fablecast/components';
import { FablecastClient, FablecastBuilder, Websocket as fws, HTTPResponse, Nullable } from 'fablecast-client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

let envVars = testEnvVars;

if (window.location.origin === 'https://prod.toolbox.mindmage.ai' || window.location.origin === 'https://toolbox.mindmage.ai') {
  envVars = prodEnvVars;
}

Amplify.configure({
  Auth: {
    Cognito: {
        userPoolId: envVars.userPoolId,
        userPoolClientId: envVars.userPoolClientId,
        loginWith: {
          oauth: {
            domain: envVars.authDomain,
            scopes: ['email', 'openid'],
            redirectSignIn: [window.location.origin],
            redirectSignOut: [window.location.origin],
            responseType: 'code',
            providers: [{'custom': envVars.idpProvider}]
          },
          email: true
        }
    }
  }
});

const theme = extendTheme({
  "colorSchemes": {
    "light": {
      "palette": themeColors
    },
    "dark": {
      "palette": themeColors
    }
  }
});

class WebsocketAdapter extends fws {
  ws?: WebSocket = undefined
  constructor(
      url: string,
      protocols: string[],
      receiveFrame: (frame: string) => Promise<any>,
      onClose: () => Promise<any>
  ) {
      super();
      this.ws = new WebSocket(url, protocols);
      this.ws.onclose = () => onClose();
      this.ws.onmessage = (e: MessageEvent) => {
          receiveFrame(e.data as string)
      };
  }
  
  async sendFrame(frame: string): Promise<boolean> {
      this.ws?.send(frame)
      return true
  }
  
  async close(): Promise<boolean> {
      this.ws?.close()
      return true
  }
}

function makeFablecastClient(accessToken: string, apiEndpoint: string, accountEndpoint: string, forgeEndpoint: string) {
  return  new FablecastBuilder()
    .withAccessToken(accessToken)
    .withAccountEndpoint(accountEndpoint)
    .withApiEndpoint(apiEndpoint)
    .withForgeEndpoint(forgeEndpoint)
    .withHttp(async (
        url: string,
        method: string,
        contentType: Nullable<string>,
        body: Nullable<string>,
        headers: Nullable<Record<string, string>>
    ) => {
        const completeHeaders: Record<string, string> = headers == null ? {} : {...headers!}
        if (contentType) {
            completeHeaders["Content-Type"] = contentType
        }
        const response = await fetch(url, {
            method: method,
            headers: completeHeaders,
            body: body || undefined,
            mode: 'cors'
        })

        const responseHeaders: Record<string, string> = {};
        response.headers.forEach((value, key) => responseHeaders[key] = value)
        // Yes, it's (value, key). The fetch headers object implements a special forEach that has it that way round.
        // Why?  You'll have to take that up with the authors of fetch.
        return new HTTPResponse(response.status, await response.text(), responseHeaders)
        
    })
    .withWebsockets((url, protocols, receiveFrame, onClose) => new WebsocketAdapter(url, protocols, receiveFrame, onClose))
    .build()
}


function App() {
  const [puppetEndpoint, setPuppetEndpoint] = React.useState<string>(localStorage.getItem('puppetEndpoint') || "https://api.puppet.prod.toolbox.mindmage.ai");
  const [puppetKey, setPuppetKey] = React.useState<string>(localStorage.getItem('puppetKey') || "");
  const [fablecastApiEndpoint, setFablecastApiEndpoint] = React.useState<string>(localStorage.getItem('fablecastEndpointHostname') || "test.api.fablecast.ai/v1");
  const [fablecastAccountEndpoint, setFablecastAccountEndpoint] = React.useState<string>(localStorage.getItem('fablecastAccountEndpointHostname') || "test.accounts.fablecast.ai/v1");
  const [fablecastForgeApiEndpoint, setFablecastForgeApiEndpoint] = React.useState<string>(localStorage.getItem('fablecastForgeEndpointHostname') || "api.test.forge.fablecast.ai/v1");
  const [fablecastAccessToken, setFablecastAccessToken] = React.useState<string>(localStorage.getItem('fablecastAccessToken') || "");
  const [authed, setAuthed] = React.useState<boolean>(false);
  const [userJwt, setUserJwt] = React.useState<string>();
  const [loginError, setLoginError] = React.useState<string>();
  const [fablecastClient, setFablecastClient] = React.useState<FablecastClient>(makeFablecastClient(fablecastAccessToken, fablecastApiEndpoint, fablecastAccountEndpoint, fablecastForgeApiEndpoint));

  React.useEffect(() => {
    setFablecastClient(makeFablecastClient(fablecastAccessToken, fablecastApiEndpoint, fablecastAccountEndpoint, fablecastForgeApiEndpoint));
  }, [setFablecastClient, fablecastAccessToken, fablecastApiEndpoint, fablecastAccountEndpoint, fablecastForgeApiEndpoint])

  const narrativeEndpoints: NarrativeEndpoints = {
    narrativeSearchEndpoint: envVars.narrativeSearchEndpoint,
    narrativePublishEndpoint: envVars.narrativePublishEndpoint,
    narrativeDeleteEndpoint: envVars.narrativeDeleteEndpoint,
    narrativeUploadEndpoint: envVars.narrativeUploadEndpoint,
    narrativeDownloadEndpoint: envVars.narrativeDownloadEndpoint
  }
  const langfuseUrl = envVars.langfuseUrl;

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
          <Box component="main" className="MainContent" sx={{ display: 'flex', minHeight: '100dvh', flex: 1 }}>
            <Sidebar langfuseUrl={langfuseUrl} />
            <Box
              component="main"
              className="MainContent"
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                overflow: 'auto',
              }}
            >
              <Outlet />
            </Box>
          </Box>
      ),
      children: (userJwt === undefined ? [] : [
        {
          path: "/",
          element: <Fablecast
                      fablecastApiEndpoint={fablecastApiEndpoint}
                      setFablecastApiEndpoint={setFablecastApiEndpoint}
                      fablecastAccountEndpoint={fablecastAccountEndpoint}
                      setFablecastAccountEndpoint={setFablecastAccountEndpoint}
                      fablecastForgeApiEndpoint={fablecastForgeApiEndpoint}
                      setFablecastForgeApiEndpoint={setFablecastForgeApiEndpoint}
                      fablecastAccessToken={fablecastAccessToken}
                      setFablecastAccessToken={setFablecastAccessToken} />,
        },
        {
          path: "/oauth2/idpresponse",
          element: <FablecastLogin/>,
        },
        {
          path: "/narratives",
          element: <Narratives narrativeEndpoints={narrativeEndpoints} userJwt={userJwt!}/>,
        },
        {
          path: "/langfuse",
          element: <LangFuse langfuseUrl={langfuseUrl}/>,
        },
        {
          path: "/puppet",
          element: <PuppetHome/>
        },
        {
          path: "/puppet/:jobQueueId",
          element: <PuppetMain puppetEndpoint={puppetEndpoint} puppetKey={puppetKey}/>,
          loader: puppetLoader,
        }
      ])
    },
  ]);

  React.useEffect(() => {    
    localStorage.setItem('fablecastEndpointHostname', fablecastApiEndpoint);
    localStorage.setItem('fablecastAccountEndpointHostname', fablecastAccountEndpoint);
    localStorage.setItem('fablecastForgeEndpointHostname', fablecastForgeApiEndpoint);
    localStorage.setItem('fablecastAccessToken', fablecastAccessToken);
    localStorage.setItem('puppetEndpoint', puppetEndpoint);
    localStorage.setItem('puppetKey', puppetKey);
  }, [fablecastApiEndpoint, fablecastAccountEndpoint, fablecastForgeApiEndpoint, fablecastAccessToken, puppetEndpoint, puppetKey]);

  React.useEffect(() => {
    async function getUserAttributes() {
        let isNowAuthed = false;
        try {
            const session = await fetchAuthSession();
            setUserJwt(session.tokens?.accessToken.toString())
            isNowAuthed = session.userSub !== undefined;
            setAuthed(isNowAuthed);
        } catch (error) {
            isNowAuthed = false;
        }

        if (!isNowAuthed) {
          
          const errorMessage = new URL(window.location.toString()).searchParams.get('error_description');
          if (errorMessage === null) {
            await signInWithRedirect({
              provider: {'custom': 'MicrosoftIdp-test'}
            })
          } else {
            setLoginError(errorMessage);
          }
          setAuthed(false);
        }
    }
    if (!authed) {
      getUserAttributes();
    } 
  }, [authed, setAuthed, setUserJwt]);

  return (
  <CssVarsProvider disableTransitionOnChange defaultMode={"dark"} theme={theme} modeStorageKey={"mmtoolboxmode"} disableNestedContext>
      <PostHogProvider client={posthog}>
          <FablecastProvider
            fablecastClient={fablecastClient}
            withLinks={false}>
              <CssBaseline />
              {authed ? (
                <Authenticator.Provider>
                    <RouterProvider router={router}/>
                    <SettingsBox
                      puppetEndpoint={puppetEndpoint} setPuppetEndpoint={setPuppetEndpoint}
                      puppetKey={puppetKey} setPuppetKey={setPuppetKey}
                      />
                </Authenticator.Provider>
              ) : (
                <Box sx={{
                  width: '100vw',
                  height: '100vh',
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: 'center'}}>
                    {loginError === undefined ? (<CircularProgress size='lg'/>) : (<Typography level="body-md">{loginError}</Typography>)}
                </Box>

              )}
          </FablecastProvider>
      </PostHogProvider>
  </CssVarsProvider>
  );
}

export default App;
